

















































































import Cell from '@/features/ui/layout/Cell.global.vue';
import { SpotRole } from '@/types/iot-portal';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import user from './user.gql';
import { AppEEDAppViewQuery } from './__generated__/AppEEDAppViewQuery';
import { AppUserFlatAccessQuery } from './__generated__/AppUserFlatAccessQuery';

type Spot = AppEEDAppViewQuery['treeNode']['first']['children']['items'][number] & { __typename: 'Spot' };

@Component({
  apollo: {
    treeNode: {
      query,
      fetchPolicy: 'cache-and-network',
    },
    me: {
      query: user,
      fetchPolicy: 'cache-and-network',
      manual: true,
      result(this: AppView, { data }: { data: AppUserFlatAccessQuery }) {
        if (data && data.me) {
          this.flatAccess = data.me.flatAccess;
        }
      },
    },
  },
  data() {
    return {
      treeNode: undefined,
      flatAccess: undefined,
      period: moment.utc().subtract(1, 'month').toDate(),
      startDateCurrentMonth: undefined,
      endDateCurrentMonth: undefined,
      startDatePreviousMonth: undefined,
      endDatePreviousMonth: undefined,
      startDateCurrentMonthPreviousYear: undefined,
      endDateCurrentMonthPreviousYear: undefined,
      displayError: false,
      displayFaqSection: false,
    };
  },
})
export default class AppView extends Vue {
  public readonly $refs!: { chart: Cell };

  private period?: Date;

  private treeNode?: AppEEDAppViewQuery['treeNode'];

  private flatAccess?: AppUserFlatAccessQuery['me']['flatAccess'];

  private displayError?: boolean;

  private showConsumptionInfoModal = false;

  private startDateCurrentMonth?: Date;
  private endDateCurrentMonth?: Date;
  private startDatePreviousMonth?: Date;
  private endDatePreviousMonth?: Date;
  private startDateCurrentMonthPreviousYear?: Date;
  private endDateCurrentMonthPreviousYear?: Date;

  private get chartHeadline(): string {
    return `Übersicht Verbrauchsdaten ${moment(this.period).toDate().getFullYear()}`;
  }

  private get maxDate(): Date {
    return moment.utc().subtract(1, 'month').toDate();
  }

  private get minDate(): Date {
    /**
     * We have to limit the datepicker start date to access date of the user for this flat.
     * In case when the access date is null limit the datepicker to 2022.
     */
    const startOfYear2022 = moment().year(2022).startOf('year');
    const userAccessDate = this.flatAccess?.accessDate;
    if (userAccessDate) {
      return moment(userAccessDate).utc().toDate() >= startOfYear2022.utc().toDate()
        ? moment(userAccessDate).utc().toDate()
        : startOfYear2022.utc().toDate();
    }
    return startOfYear2022.utc().toDate();
  }

  private get spots(): Spot[] {
    return (this.treeNode?.first.children.items as Spot[]) ?? [];
  }

  private get roles(): SpotRole[] {
    const roles = [];

    if (this.spots.find(({ role }) => role === SpotRole.HEAT_METER_COUNTER)) {
      roles.push(SpotRole.HEAT_METER_COUNTER);
    }

    if (this.spots.find(({ role }) => role === SpotRole.HEAT_COST_ALLOCATOR)) {
      roles.push(SpotRole.HEAT_COST_ALLOCATOR);
    }

    if (this.spots.find(({ role }) => role === SpotRole.WATER_METER_HOT)) {
      roles.push(SpotRole.WATER_METER_HOT);
    }

    return roles;
  }

  private get hasHeatRole(): boolean {
    return this.roles.includes(SpotRole.HEAT_COST_ALLOCATOR) || this.roles.includes(SpotRole.HEAT_METER_COUNTER);
  }

  private get hasWaterRole(): boolean {
    return this.roles.includes(SpotRole.WATER_METER_HOT);
  }

  private get widgetsContainerFlexBasis(): number {
    return this.hasHeatRole && this.hasWaterRole ? 0.5 : 0.25;
  }

  private scrollToChart(): void {
    window.scrollTo({
      top: this.$refs.chart.$el.getBoundingClientRect().top + window.scrollY - 76, // substract the header height
      behavior: 'smooth',
    });
  }

  private onStartDateCurrentMonth(startDate: Date): void {
    this.startDateCurrentMonth = startDate ? new Date(startDate) : undefined;
  }

  private onEndDateCurrentMonth(endDate: Date): void {
    this.endDateCurrentMonth = endDate ? new Date(endDate) : undefined;
  }

  private onStartDatePreviousMonth(startDate: Date): void {
    this.startDatePreviousMonth = startDate ? new Date(startDate) : undefined;
  }

  private onEndDatePreviousMonth(endDate: Date): void {
    this.endDatePreviousMonth = endDate ? new Date(endDate) : undefined;
  }

  private onStartDateCurrentMonthPreviousYear(startDate: Date): void {
    this.startDateCurrentMonthPreviousYear = startDate ? new Date(startDate) : undefined;
  }

  private onEndDateCurrentMonthPreviousYear(endDate: Date): void {
    this.endDateCurrentMonthPreviousYear = endDate ? new Date(endDate) : undefined;
  }
}
