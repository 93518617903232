











import { ConsumptionMixin } from '@/features/domain-ui/eed-consumption/mixins/consumption-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import LastYearComparisonConsumption from './LastYearComparisonConsumption.vue';

@Component({
  components: {
    LastYearComparisonConsumption,
  },
})
export default class LastYearConsumptionWidget extends Mixins(ConsumptionMixin) {}
