











import { ConsumptionMixin } from '@/features/domain-ui/eed-consumption/mixins/consumption-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import LastMonthComparisonConsumption from './LastMonthComparisonConsumption.vue';

@Component({
  components: {
    LastMonthComparisonConsumption,
  },
})
export default class LastMonthConsumptionWidget extends Mixins(ConsumptionMixin) {}
