
























































import { Component, Vue } from 'vue-property-decorator';
import energieverbrauch from '@/features/app-eed/assets/energieverbrauch.png';

@Component
export default class Info extends Vue {
  public openEnergyConsumptionPage(): void {
    window.open(energieverbrauch, '_blank');
  }
}
