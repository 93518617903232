









import { CONSUMPTION_MIXIN_META } from '@/features/domain-ui/eed-consumption/mixins/constants';
import { ConsumptionMixin } from '@/features/domain-ui/eed-consumption/mixins/consumption-mixin';
import { ContinuousConsumptionMetricWithPoints, PeriodType } from '@/features/domain-ui/eed-consumption/model';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { MetricResolutionAggregator, SpotRole } from '@/types/iot-portal';
import { AppEEDConsumptionMixinQuery } from '@/features/domain-ui/eed-consumption/mixins/__generated__/AppEEDConsumptionMixinQuery';
import moment from 'moment';

type DataPoint = {
  value: number;
};

@Component
export default class CurrentYearConsumption extends Mixins(ConsumptionMixin) {
  protected readonly aggregator = MetricResolutionAggregator.LAST;
  protected readonly aggregationInterval = 'P1M';
  protected readonly withPoints = true;
  protected periodType = PeriodType.CURRENT_YEAR;
  protected withBeforeMetrics = true;

  private totalYearlyConsumption = 0;

  private get isSelectedPeriodBeforeStartOfCurrentYear(): boolean {
    return moment(this.period).toDate() < moment().startOf('year').toDate();
  }

  private get isAccessDateBeforeStartOfSelectedYear(): boolean {
    return moment(this.userAccessDate).toDate() < moment(this.period).startOf('year').toDate();
  }

  private get currentPeriodType(): PeriodType {
    return this.isSelectedPeriodBeforeStartOfCurrentYear
      ? PeriodType.CURRENT_YEAR
      : PeriodType.CURRENT_YEAR_UNTIL_PREVIOUS_MONTH;
  }

  @Watch('period')
  @Watch('groupedSpots')
  private getDataPointsPerRole(): void {
    this.periodType = this.currentPeriodType;
    this.withBeforeMetrics = this.isAccessDateBeforeStartOfSelectedYear;

    const heatingDataPoints = (
      this.groupedSpots?.[
        this.roles.includes(SpotRole.HEAT_COST_ALLOCATOR) ? SpotRole.HEAT_COST_ALLOCATOR : SpotRole.HEAT_METER_COUNTER
      ] ?? []
    )
      .map((spot) => this.getConsumptionPoints(spot))
      .reduce((cumulated, dataPointsPerSpot) => {
        for (let i = 0; i < dataPointsPerSpot.length; i++) {
          if (i === 0 || dataPointsPerSpot[i - 1].value !== 0) {
            cumulated += dataPointsPerSpot[i].value;
          }
        }
        return cumulated > 0 ? cumulated : 0;
      }, 0);

    const warmWaterDataPoints = (this.groupedSpots?.[SpotRole.WATER_METER_HOT] ?? [])
      .map((spot) => this.getConsumptionPoints(spot))
      .reduce((cumulated, dataPointsPerSpot) => {
        for (let i = 0; i < dataPointsPerSpot.length; i++) {
          if (i === 0 || dataPointsPerSpot[i - 1].value !== 0) {
            cumulated += dataPointsPerSpot[i].value;
          }
        }
        return cumulated > 0 ? cumulated : 0;
      }, 0);

    this.totalYearlyConsumption = heatingDataPoints + warmWaterDataPoints;
  }

  private getConsumptionPoints(spot: AppEEDConsumptionMixinQuery['spots']['items'][number]): DataPoint[] {
    const beforeMetric = spot.beforeMetrics[0] as ContinuousConsumptionMetricWithPoints | undefined;
    const points = (spot.metrics[0] as ContinuousConsumptionMetricWithPoints)?.points ?? [];
    const months =
      this.period.getFullYear() === moment().toDate().getFullYear()
        ? Array.from(Array(moment().toDate().getMonth() + 1).keys())
        : Array.from(Array(13).keys());
    const beforeMetricValue =
      this.isAccessDateBeforeStartOfSelectedYear && points.length ? beforeMetric?.latest.continuous : 0;

    const consumptionValues = months.map((month) => {
      const date = new Date(this.period.getFullYear(), month, 0);
      const value = points.find(({ time }) => {
        const pointDate = new Date(time);
        return (
          pointDate.getFullYear() === date.getFullYear() &&
          pointDate.getMonth() === date.getMonth() &&
          moment(pointDate).toDate() >= moment(this.userAccessDate).subtract(1, 'month').startOf('month').toDate()
        );
      })?.continuous;
      return {
        value: value ?? 0,
      };
    });

    const diffs = consumptionValues.map(({ value }, index) => ({
      value: CONSUMPTION_MIXIN_META[spot.role].consumptionCalculationMethod(
        value,
        index === 0 ? beforeMetricValue ?? 0 : consumptionValues[index - 1]?.value,
        this.attributes,
        spot.attributes,
      ),
    }));
    return diffs;
  }
}
