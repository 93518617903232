

























import { BooleanProp, DateProp } from '@/util/prop-decorators';
import { DateTime } from 'luxon';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ConsumptionInfoModal extends Vue {
  @BooleanProp()
  private readonly show!: boolean;

  @DateProp()
  private startDateCurrentMonth!: Date;
  @DateProp()
  private endDateCurrentMonth!: Date;
  @DateProp()
  private startDatePreviousMonth!: Date;
  @DateProp()
  private endDatePreviousMonth!: Date;
  @DateProp()
  private startDateCurrentMonthPreviousYear!: Date;
  @DateProp()
  private endDateCurrentMonthPreviousYear!: Date;

  private get currentMonthStartDate(): string | undefined {
    return this.startDateCurrentMonth
      ? DateTime.fromJSDate(this.startDateCurrentMonth).toUTC().toFormat('dd.LL.yyyy')
      : undefined;
  }

  private get currentMonthEndDate(): string | undefined {
    return this.endDateCurrentMonth
      ? DateTime.fromJSDate(this.endDateCurrentMonth).toUTC().toFormat('dd.LL.yyyy')
      : undefined;
  }

  private get previousMonthStartDate(): string | undefined {
    return this.startDatePreviousMonth
      ? DateTime.fromJSDate(this.startDatePreviousMonth).toUTC().toFormat('dd.LL.yyyy')
      : undefined;
  }

  private get previousMonthEndDate(): string | undefined {
    return this.endDatePreviousMonth
      ? DateTime.fromJSDate(this.endDatePreviousMonth).toUTC().toFormat('dd.LL.yyyy')
      : undefined;
  }

  private get currentMonthPreviousYearStartDate(): string | undefined {
    return this.startDateCurrentMonthPreviousYear
      ? DateTime.fromJSDate(this.startDateCurrentMonthPreviousYear).toUTC().toFormat('dd.LL.yyyy')
      : undefined;
  }

  private get currentMonthPreviousYearEndDate(): string | undefined {
    return this.endDateCurrentMonthPreviousYear
      ? DateTime.fromJSDate(this.endDateCurrentMonthPreviousYear).toUTC().toFormat('dd.LL.yyyy')
      : undefined;
  }
}
