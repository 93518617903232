




import { Component, Vue } from 'vue-property-decorator';
import CurrentMonthConsumption from './CurrentMonthConsumption.vue';

@Component({
  components: {
    CurrentMonthConsumption,
  },
})
export default class CurrentMonthConsumptionWidget extends Vue {}
