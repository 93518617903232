

















































import CurrentYearConsumption from '@/features/app-eed/components/widgets/CurrentYearConsumption.vue';
import { PropertyTreeNodeAttribute } from '@/features/domain-ui/eed-consumption/model';
import { SpotRole } from '@/types/iot-portal';
import { ArrayProp, DateProp, StringProp } from '@/util/prop-decorators';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    CurrentYearConsumption,
  },
})
export default class WidgetsContainer extends Vue {
  @StringProp()
  private readonly treeNodeId!: string;

  @DateProp()
  private readonly period!: Date;

  @ArrayProp()
  private readonly attributes!: PropertyTreeNodeAttribute[];

  @ArrayProp()
  private readonly roles!: SpotRole[];

  private lastUpdateValue: string | null = null;

  private get selectedDate(): Date {
    return moment(this.period).toDate().getFullYear() < moment().toDate().getFullYear()
      ? new Date(moment(this.period).toDate().getFullYear(), 11, 31)
      : new Date();
  }

  private onLastUpdate(lastUpdateTimestamp: string | null): void {
    this.lastUpdateValue = lastUpdateTimestamp;
  }

  private get lastUpdateDate(): string | null {
    if (this.lastUpdateValue == null) {
      return null;
    }
    return moment(this.lastUpdateValue).format('D. MMM YYYY');
  }

  private get heatConsumptionRole(): SpotRole {
    return this.roles.includes(SpotRole.HEAT_COST_ALLOCATOR)
      ? SpotRole.HEAT_COST_ALLOCATOR
      : SpotRole.HEAT_METER_COUNTER;
  }

  private get hasHeatRole(): boolean {
    return this.roles.includes(SpotRole.HEAT_COST_ALLOCATOR) || this.roles.includes(SpotRole.HEAT_METER_COUNTER);
  }

  private get hasWaterRole(): boolean {
    return this.roles.includes(SpotRole.WATER_METER_HOT);
  }

  private get wigdetFlexBasis(): number {
    return this.hasHeatRole && this.hasWaterRole ? 0.5 : 1;
  }
}
