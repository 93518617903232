















import {
  ConsumptionWidgetIcon,
  CONSUMPTION_WIDGET_META,
} from '@/features/domain-ui/eed-consumption/consumption-widget/constants';
import { ConsumptionMixin } from '@/features/domain-ui/eed-consumption/mixins/consumption-mixin';
import { PeriodType } from '@/features/domain-ui/eed-consumption/model';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class ConsumptionWidget extends Mixins(ConsumptionMixin) {
  protected readonly periodType = PeriodType.CURRENT_MONTH;

  private get icon(): ConsumptionWidgetIcon | undefined {
    return CONSUMPTION_WIDGET_META[this.roles[0]]?.icon;
  }

  private get label(): string | undefined {
    return CONSUMPTION_WIDGET_META[this.roles[0]]?.label;
  }

  private get formattedConsumption(): string {
    return this.formatConsumption(this.consumption);
  }
}
