










import { Component, Vue } from 'vue-property-decorator';
import { PUBLIC_PATH } from '@/env';

@Component
export default class Footer extends Vue {
  private termsOfUsePdf = `${PUBLIC_PATH || ''}files/terms.pdf`;
}
