
















































import { SpotRole } from '@/types/iot-portal';
import { EnumProp, StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import { AppEEDWidgetViewQuery, AppEEDWidgetViewQueryVariables } from './__generated__/AppEEDWidgetViewQuery';

@Component({
  apollo: {
    view: {
      query,
      manual: true,
      variables(this: WidgetView): AppEEDWidgetViewQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
      result(this: WidgetView, { data }: ApolloQueryResult<AppEEDWidgetViewQuery>): void {
        this.treeNode = data.treeNode;
        this.spots = data.spots;
      },
    },
  },
  data() {
    return { treeNode: undefined, period: moment.utc().subtract(1, 'month').toDate() };
  },
})
export default class WidgetView extends Vue {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @EnumProp(SpotRole.HEAT_COST_ALLOCATOR, SpotRole.HEAT_METER_COUNTER, SpotRole.WATER_METER_HOT)
  private readonly spotRole!: SpotRole;

  private treeNode?: AppEEDWidgetViewQuery['treeNode'];
  private spots?: AppEEDWidgetViewQuery['spots'];
  private period!: Date;

  private get hasHeatMeterCounters(): boolean {
    if (!this.spots) {
      return false;
    }

    return this.spots?.items.find(({ role }) => role === SpotRole.HEAT_METER_COUNTER) !== undefined;
  }

  private get hasHotWaterMeters(): boolean {
    if (!this.spots) {
      return false;
    }

    return this.spots?.items.find(({ role }) => role === SpotRole.WATER_METER_HOT) !== undefined;
  }

  private get widgetRole(): SpotRole {
    if (this.spotRole === SpotRole.WATER_METER_HOT) {
      return this.spotRole;
    }

    if (!this.hasHeatMeterCounters) {
      return SpotRole.HEAT_COST_ALLOCATOR;
    }
    return SpotRole.HEAT_METER_COUNTER;
  }

  private get year(): string {
    return moment(this.period).format('YYYY');
  }

  private get month(): string {
    return moment(this.period).format('MMMM');
  }
}
