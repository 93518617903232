



















import { ConsumptionMixin } from '@/features/domain-ui/eed-consumption/mixins/consumption-mixin';
import { PeriodType } from '@/features/domain-ui/eed-consumption/model';
import { NumberProp } from '@/util/prop-decorators';
import moment from 'moment';
import numeral from '@/util/numeral-locale';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class LastMonthComparisonConsumption extends Mixins(ConsumptionMixin) {
  @NumberProp()
  private readonly currentConsumption!: number;

  protected readonly periodType = PeriodType.PREVIOUS_MONTH;

  private get percentageDiff(): number {
    return this.computePercentageDiff(this.currentConsumption);
  }

  private get previousConsumption(): number {
    if (this.percentageDiff === 100) {
      return 0;
    }

    return (100 * this.currentConsumption) / (this.percentageDiff + 100);
  }

  private get monthBefore(): Date {
    return moment(this.period).subtract(1, 'month').toDate();
  }

  private get year(): string {
    return moment(this.monthBefore).format('YYYY');
  }

  private get month(): string {
    return moment(this.monthBefore).format('MMMM');
  }

  private get hasData(): boolean {
    return !isNaN(this.percentageDiff);
  }

  private get formattedPercentageDiff(): string {
    if (this.percentageDiff < 0) {
      if (Number.isInteger(this.percentageDiff)) {
        return numeral(this.percentageDiff).format('-0%');
      } else {
        return numeral(this.percentageDiff).format('-0.0%');
      }
    } else {
      if (Number.isInteger(this.percentageDiff)) {
        return numeral(this.percentageDiff).format('+0%');
      }
      return numeral(this.percentageDiff).format('+0.0%');
    }
  }
}
