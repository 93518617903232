




import { CONSUMPTION_MIXIN_META } from '@/features/domain-ui/eed-consumption/mixins/constants';
import { ConsumptionMixin } from '@/features/domain-ui/eed-consumption/mixins/consumption-mixin';
import { AppEEDConsumptionMixinQuery } from '@/features/domain-ui/eed-consumption/mixins/__generated__/AppEEDConsumptionMixinQuery';
import { ContinuousConsumptionMetricWithPoints, PeriodType } from '@/features/domain-ui/eed-consumption/model';
import { MetricResolutionAggregator, SpotRole } from '@/types/iot-portal';
import { formatConsumption } from '@/features/domain-ui/eed-consumption/util';
import { ChartData, ChartOptions, ChartTooltipItem } from 'chart.js';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import moment from 'moment';

type DataPoint = {
  comparableValue: number;
  value: number;
  monthAndYear: Date;
};

@Component
export default class ConsumptionChart extends Mixins(ConsumptionMixin) {
  protected readonly periodType = PeriodType.CURRENT_AND_PREVIOUS_YEAR_PREVIOUS_MONTH;
  protected readonly aggregator = MetricResolutionAggregator.LAST;
  protected readonly aggregationInterval = 'P1M';
  protected readonly withPoints = true;
  protected withBeforeMetrics = true;

  private heatingDataPointsSelectedYear: DataPoint[] = [];
  private warmWaterDataPointsSelectedYear: DataPoint[] = [];

  private heatingDataPreviousYear: DataPoint[] = [];
  private warmWaterDataPointsPreviousYear: DataPoint[] = [];

  @Watch('period')
  @Watch('groupedSpots')
  private getDataPointsPerRole(): void {
    this.withBeforeMetrics = this.isAccessDateBeforeStartOfSelectedYear;

    this.heatingDataPointsSelectedYear = (
      this.groupedSpots?.[
        this.roles.includes(SpotRole.HEAT_COST_ALLOCATOR) ? SpotRole.HEAT_COST_ALLOCATOR : SpotRole.HEAT_METER_COUNTER
      ] ?? []
    )
      .map((spot) => this.getConsumptionPoints(this.period.getFullYear(), spot))
      .reduce((cumulated, dataPointsPerSpot) => {
        for (let i = 0; i < dataPointsPerSpot.length; i++) {
          cumulated[i] = {
            comparableValue: (cumulated[i]?.value ?? 0) + dataPointsPerSpot[i].value,
            value: (cumulated[i]?.value ?? 0) + dataPointsPerSpot[i].value,
            monthAndYear: dataPointsPerSpot[i].monthAndYear,
          };
        }
        return cumulated;
      }, []);

    this.heatingDataPreviousYear = (
      this.groupedSpots?.[
        this.roles.includes(SpotRole.HEAT_COST_ALLOCATOR) ? SpotRole.HEAT_COST_ALLOCATOR : SpotRole.HEAT_METER_COUNTER
      ] ?? []
    )
      .map((spot) => this.getConsumptionPoints(this.period.getFullYear() - 1, spot))
      .reduce((cumulated, dataPointsPerSpot) => {
        for (let i = 0; i < dataPointsPerSpot.length; i++) {
          cumulated[i] = {
            comparableValue: (cumulated[i]?.value ?? 0) + dataPointsPerSpot[i].value,
            value: (cumulated[i]?.value ?? 0) + dataPointsPerSpot[i].value,
            monthAndYear: dataPointsPerSpot[i].monthAndYear,
          };
        }
        return cumulated;
      }, []);

    this.warmWaterDataPointsSelectedYear = (this.groupedSpots?.[SpotRole.WATER_METER_HOT] ?? [])
      .map((spot) => this.getConsumptionPoints(this.period.getFullYear(), spot))
      .reduce((cumulated, dataPointsPerSpot) => {
        for (let i = 0; i < dataPointsPerSpot.length; i++) {
          cumulated[i] = {
            comparableValue: (cumulated[i]?.value ?? 0) + dataPointsPerSpot[i].value,
            value: (cumulated[i]?.value ?? 0) + dataPointsPerSpot[i].value,
            monthAndYear: dataPointsPerSpot[i].monthAndYear,
          };
        }
        return cumulated;
      }, []);

    this.warmWaterDataPointsPreviousYear = (this.groupedSpots?.[SpotRole.WATER_METER_HOT] ?? [])
      .map((spot) => this.getConsumptionPoints(this.period.getFullYear() - 1, spot))
      .reduce((cumulated, dataPointsPerSpot) => {
        for (let i = 0; i < dataPointsPerSpot.length; i++) {
          cumulated[i] = {
            comparableValue: (cumulated[i]?.value ?? 0) + dataPointsPerSpot[i].value,
            value: (cumulated[i]?.value ?? 0) + dataPointsPerSpot[i].value,
            monthAndYear: dataPointsPerSpot[i].monthAndYear,
          };
        }
        return cumulated;
      }, []);
  }

  private get isAccessDateBeforeStartOfSelectedYear(): boolean {
    return moment(this.userAccessDate).toDate() < moment(this.period).startOf('year').toDate();
  }

  private get hasWarmWaterData(): boolean {
    return (
      this.roles.includes(SpotRole.WATER_METER_HOT) &&
      (this.warmWaterDataPointsSelectedYear.length > 0 || this.warmWaterDataPointsPreviousYear.length > 0)
    );
  }

  private get hasHeatingData(): boolean {
    return (
      (this.roles.includes(SpotRole.HEAT_COST_ALLOCATOR) || this.roles.includes(SpotRole.HEAT_METER_COUNTER)) &&
      (this.heatingDataPointsSelectedYear.length > 0 || this.heatingDataPreviousYear.length > 0)
    );
  }

  private get chartSets(): {
    label: string;
    name: string;
    values: number[];
    comparableValues: number[];
    color: string;
  }[] {
    const sets = [];
    this.hasHeatingData
      ? sets.push(
          {
            label: `Heizung ${this.period.getFullYear()}`,
            name: 'heatingSelectedYear',
            values: this.heatingDataPointsSelectedYear
              .filter(({ monthAndYear }) => monthAndYear.getFullYear() === this.period.getFullYear())
              .map(({ value }) => (value > 0 ? value : 0)),
            comparableValues: this.heatingDataPointsSelectedYear
              .filter(({ monthAndYear }) => monthAndYear.getFullYear() === this.period.getFullYear())
              .map(({ comparableValue }) => comparableValue),
            color: '#e60000',
          },
          {
            label: `Heizung ${this.period.getFullYear() - 1}`,
            name: 'heatingPreviousYear',
            values: this.heatingDataPreviousYear
              .filter(({ monthAndYear }) => monthAndYear.getFullYear() === this.period.getFullYear() - 1)
              .map(({ value }) => (value > 0 ? value : 0)),
            comparableValues: this.heatingDataPreviousYear
              .filter(({ monthAndYear }) => monthAndYear.getFullYear() === this.period.getFullYear() - 1)
              .map(({ comparableValue }) => comparableValue),
            color: '#ff7373',
          },
        )
      : null;

    this.hasWarmWaterData
      ? sets.push(
          {
            label: `Warmwasser ${this.period.getFullYear()}`,
            name: 'warmWaterSelectedYear',
            values: this.warmWaterDataPointsSelectedYear
              .filter(({ monthAndYear }) => monthAndYear.getFullYear() === this.period.getFullYear())
              .map(({ value }) => (value > 0 ? value : 0)),
            comparableValues: this.warmWaterDataPointsSelectedYear
              .filter(({ monthAndYear }) => monthAndYear.getFullYear() === this.period.getFullYear())
              .map(({ comparableValue }) => comparableValue),
            color: '#4a90e2',
          },
          {
            label: `Warmwasser ${this.period.getFullYear() - 1}`,
            name: 'warmWaterPreviousYear',
            values: this.warmWaterDataPointsPreviousYear
              .filter(({ monthAndYear }) => monthAndYear.getFullYear() === this.period.getFullYear() - 1)
              .map(({ value }) => (value > 0 ? value : 0)),
            comparableValues: this.warmWaterDataPointsPreviousYear
              .filter(({ monthAndYear }) => monthAndYear.getFullYear() === this.period.getFullYear() - 1)
              .map(({ comparableValue }) => comparableValue),
            color: '#a4c7f0',
          },
        )
      : null;
    return sets;
  }

  private get chartData(): ChartData {
    return {
      labels: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      datasets: this.chartSets.map(({ label, values, color }, idxChartSet) => ({
        backgroundColor: 'transparent',
        borderColor: color,
        pointBackgroundColor: color,
        pointHoverBackgroundColor: color,
        data: values.map((value, index) => (this.chartSets[idxChartSet].comparableValues[index - 1] === 0 ? 0 : value)),
        pointHoverRadius: 3.5,
        pointRadius: 3,
        borderWidth: 2,
        label,
      })),
    };
  }

  private get chartOptions(): ChartOptions {
    return {
      layout: {
        padding: {
          top: 30,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        yAxes: [{ display: true, scaleLabel: { labelString: 'KWh', display: true } }],
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem: ChartTooltipItem, data: ChartData) => {
            const datasetIndex = tooltipItem.datasetIndex ?? 0;
            const index = tooltipItem.index ?? 0;

            const label = data && data.datasets ? data.datasets[datasetIndex].label : '';
            const value = data && data.datasets ? (data.datasets[datasetIndex].data as number[])[index] : 0;

            const formattedValue = formatConsumption(value) ?? 0;
            return `${label}: ${formattedValue}`;
          },
        },
      },
    };
  }

  private getConsumptionPoints(year: number, spot: AppEEDConsumptionMixinQuery['spots']['items'][number]): DataPoint[] {
    const points = (spot.metrics[0] as ContinuousConsumptionMetricWithPoints)?.points ?? [];
    const beforeMetric = spot.beforeMetrics[0] as ContinuousConsumptionMetricWithPoints | undefined;
    const beforeMetricValue =
      this.isAccessDateBeforeStartOfSelectedYear && points.length ? beforeMetric?.latest.continuous : 0;
    const months =
      year === moment().toDate().getFullYear()
        ? Array.from(Array(moment().toDate().getMonth() + 1).keys())
        : Array.from(Array(13).keys());

    const consumptionValues = months.map((month) => {
      const date = new Date(year, month, 0);
      const value = points.find(({ time }) => {
        const pointDate = new Date(time);
        return (
          pointDate.getFullYear() === date.getFullYear() &&
          pointDate.getMonth() === date.getMonth() &&
          moment(pointDate).toDate() >= moment(this.userAccessDate).subtract(1, 'month').startOf('month').toDate()
        );
      })?.continuous;
      return {
        value: value ?? 0,
        monthAndYear: date,
      };
    });

    const diffs = consumptionValues.map(({ value, monthAndYear }, index) => ({
      comparableValue: 0,
      value: CONSUMPTION_MIXIN_META[spot.role].consumptionCalculationMethod(
        value,
        index === 0 ? beforeMetricValue ?? 0 : consumptionValues[index - 1].value,
        this.attributes,
        spot.attributes,
      ),
      monthAndYear: monthAndYear,
    }));
    return diffs;
  }
}
